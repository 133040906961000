import axios from 'axios'
axios.defaults.timeout = 5000; //响应时间
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'; //配置请求头
//设置LocalStorages数据
function setlocal(cname, cvalue) {
    localStorage.setItem(cname, cvalue)
}
//获取LocalStorages数据
function getlocal(cname) {
    return localStorage.getItem(cname)
}
//清除LocalStorages数据
function clearlocal() {
    localStorage.clear()
}

export default {
    setlocal,
    getlocal,
    clearlocal
}