<template>
    <div class="mask">
        <div class="push-info">
            <a-row :gutter="10" style="height: 100%">
                <a-col :span="16" class="phone-pre">
                    <div class="phone-frame"
                         style="max-width: 350px; margin: 0 auto;  ">
                        <h2>{{ Model.Name }}</h2>

                        <p style="color:#777">{{ Model.Author }}</p>

                        <p style="text-align: justify; word-break: break-all">
                            {{ Model.Contents }}
                        </p>

                        <img :src="Img" style="width: 100%" />
                    </div>
                </a-col>
                <a-col :span="8" class="right-cont">
                    <a-form-model layout="horizontal"
                                  style="padding: 10px 00px; height: auto"
                                  :rules="rules"
                                  ref="ruleForm"
                                  :model="Model">
                        <a-tabs default-active-key="0">
                            <a-tab-pane key="0" tab="传播推送" style="padding: 20px">
                                <a-row type="flex" justify="space-around">
                                    <a-col :span="24" align="left">
                                        <a-form-model-item label="标题"
                                                           labelAlign="left"
                                                           style="margin-bottom: 10px"
                                                           :label-col="formItemLayout.labelCol"
                                                           :wrapper-col="formItemLayout.wrapperCol"
                                                           prop="Name">
                                            <a-input placeholder="标题"
                                                     v-model="Model.Name"
                                                     labelAlign="left"
                                                     style="outline: none"
                                                     @change="name_change"
                                                     onkeyup="this.value=this.value.replace(/\s+/g,'')"
                                                     :maxLength="100"
                                                     :disabled="disabled"></a-input>
                                        </a-form-model-item>
                                        <div class="line"></div>
                                        <a-form-model-item label="作者"
                                                           labelAlign="left"
                                                           style="margin-bottom: 10px"
                                                           :label-col="formItemLayout.labelCol"
                                                           :wrapper-col="formItemLayout.wrapperCol"
                                                           prop="Author">
                                            <a-input placeholder="作者"
                                                     type="input"
                                                     v-model="Model.Author"
                                                     onkeyup="this.value=this.value.replace(/\s+/g,'')"
                                                     :disabled="disabled"
                                                     :maxLength="50"
                                                     style="outline: none"></a-input>
                                        </a-form-model-item>
                                        <a-form-model-item label="摘要"
                                                           labelAlign="left"
                                                           style="margin-bottom: 10px"
                                                           :label-col="formItemLayout.labelCol"
                                                           :wrapper-col="formItemLayout.wrapperCol"
                                                           prop="Summary">
                                            <a-input placeholder="摘要"
                                                     type="input"
                                                     v-model="Model.Summary"
                                                     onkeyup="this.value=this.value.replace(/\s+/g,'')"
                                                     :maxLength="20"
                                                     :disabled="disabled"
                                                     style="outline: none"></a-input>
                                        </a-form-model-item>
                                    </a-col>
                                </a-row>

                                <a-row>

                                    <a-col :span="24">
                                        <a-form-model-item label='朋友圈内容'
                                                           labelAlign="left"
                                                           style="margin-bottom: 10px"
                                                           :label-col="formItemLayout.labelCol"
                                                           :wrapper-col="formItemLayout.wrapperCol"
                                                           prop="Contents">
                                            <a-input placeholder="请输入内容"
                                                     type="textarea"
                                                     rows="6"
                                                     v-model="Model.Contents"
                                                     onkeyup="this.value=this.value.replace(/\s+/g,'')"
                                                     :disabled="disabled"></a-input>
                                        </a-form-model-item>
                                    </a-col>
                                </a-row>
                                <a-row>

                                    <a-col :span="24">
                                        <a-form-item label="上传图片" labelAlign="left">
                                            <a-upload name="image"
                                                      :data="ParamData"
                                                      list-type="picture-card"
                                                      class="avatar-uploader"
                                                      action="/FileServer/Upload"
                                                      :fileList="Model.fileList"
                                                      @change="imgChange"
                                                      :disabled="disabled">
                                                <div v-if="Model.fileList.length < 1">
                                                    <a-icon type="plus" />
                                                    <div class="ant-upload-text">上传图片</div>
                                                </div>
                                            </a-upload>
                                        </a-form-item>
                                    </a-col>
                                </a-row>
                                <a-row>
                                    <a-col :span="24" style="display:none;">
                                        <a-form-model-item label="分享时间" labelAlign="left"
                                                           :label-col="formItemLayout.labelCol"
                                                           :wrapper-col="formItemLayout.wrapperColline"
                                                           prop="ShareTime">
                                            <a-date-picker v-model="Model.ShareTime"
                                                           style="width: 100%"
                                                           show-time
                                                           type="date"
                                                           format="YYYY-MM-DD HH:mm"
                                                           placeholder="选择开始时间"
                                                           :disabled="disabled" />
                                        </a-form-model-item>
                                    </a-col>
                                </a-row>
                                <a-row type="flex"
                                       style="text-align: center; margin: 15px"
                                       justify="center">
                                    <a-col :span="8">
                                        <a-button size="large"
                                                  type="primary"
                                                  @click="SubmitModel('ruleForm',0)"
                                                  :disabled="disabled">保存</a-button>
                                    </a-col>
                                    <a-col :span="8">
                                        <a-button size="large"
                                                  type="primary"
                                                  @click="SubmitModel('ruleForm',1)"
                                                  :disabled="disabled">保存并推送</a-button>
                                    </a-col>
                                    <a-col :span="8">
                                        <a-button size="large" type="" @click="close(true)">取消</a-button>
                                    </a-col>
                                </a-row>
                            </a-tab-pane>
                            <a-tab-pane key="1" tab="传播激励" style="padding: 20px">
                                <a-form-model-item label="开始时间" labelAlign="left"
                                                   :label-col="formItemLayout.labelCol"
                                                   :wrapper-col="formItemLayout.wrapperColline"
                                                   prop="BeginTime">
                                    <a-date-picker v-model="Model.MaterialAward.BeginTime"
                                                   style="width: 100%"
                                                   show-time
                                                   type="date"
                                                   :disabled-date="disabledStartDate"
                                                   format="YYYY-MM-DD HH:mm:ss"
                                                   placeholder="选择开始时间"
                                                   @openChange="handleStartOpenChange"
                                                   :disabled="disabled" />
                                </a-form-model-item>
                                <a-form-model-item label="结束时间" labelAlign="left"
                                                   :label-col="formItemLayout.labelCol"
                                                   :wrapper-col="formItemLayout.wrapperColline"
                                                   prop="EndTime">
                                    <a-date-picker show-time
                                                   style="width: 100%"
                                                   type="date"
                                                   :disabled-date="disabledEndDate"
                                                   format="YYYY-MM-DD HH:mm:ss"
                                                   placeholder="选择结束时间"
                                                   :open="endOpen"
                                                   @openChange="handleEndOpenChange"
                                                   v-model="Model.MaterialAward.EndTime"
                                                   :disabled="disabled" />
                                </a-form-model-item>

                                <a-form-model-item label="浏览时长(秒)" labelAlign="left"
                                                   prop="ReadSecond"
                                                   :label-col="formItemLayout.labelCol"
                                                   :wrapper-col="formItemLayout.wrapperColline">
                                    <a-input-number :min="1"
                                                    :step="1"
                                                    v-model="Model.MaterialAward.ReadSecond"
                                                    :disabled="disabled" />
                                </a-form-model-item>
                                <a-form-model-item has-feedback
                                                   prop="ReadMoney"
                                                   label="阅读金额" labelAlign="left"
                                                   :label-col="formItemLayout.labelCol"
                                                   :wrapper-col="formItemLayout.wrapperColline">
                                    <a-input-number :min="0"
                                                    :step="0"
                                                    v-model="Model.MaterialAward.ReadMoney"
                                                    :disabled="disabled"
                                                    :precision="2" />
                                </a-form-model-item>
                                <a-form-model-item has-feedback
                                                   prop="PersonMaxMoney"
                                                   label="单人最高金额" labelAlign="left"
                                                   :label-col="formItemLayout.labelCol"
                                                   :wrapper-col="formItemLayout.wrapperColline">
                                    <a-input-number :min="0"
                                                    :step="0"
                                                    v-model="Model.MaterialAward.PersonMaxMoney"
                                                    :disabled="disabled"
                                                    :precision="2" />
                                </a-form-model-item>
                                <a-form-model-item has-feedback
                                                   prop="BudgetMoney"
                                                   label="活动最高金额" labelAlign="left"
                                                   :label-col="formItemLayout.labelCol"
                                                   :wrapper-col="formItemLayout.wrapperColline">
                                    <a-input-number :min="0"
                                                    :step="0"
                                                    v-model="Model.MaterialAward.BudgetMoney"
                                                    :disabled="disabled"
                                                    :precision="2" />
                                </a-form-model-item>

                                <a-form-model-item label="位置区域" labelAlign="left"
                                                   :label-col="formItemLayout.labelCol"
                                                   :wrapper-col="formItemLayout.wrapperCol">
                                    <a-switch v-model="Model.MaterialAward.GPSLimit"
                                              :disabled="disabled">
                                        <a-icon slot="checkedChildren" type="check" />
                                        <a-icon slot="unCheckedChildren" type="close" />
                                    </a-switch>
                                    <a-button type="primary"
                                              value="点击选择"
                                              @click="map_display"
                                              v-show="Model.MaterialAward.GPSLimit"
                                              size="small"
                                              style="margin-left: 12px"
                                              :disabled="disabled">点击选择</a-button>
                                </a-form-model-item>
                                <a-form-model-item label="地址" labelAlign="left"
                                                   v-show="Model.MaterialAward.GPSLimit"
                                                   :label-col="formItemLayout.labelCol"
                                                   :wrapper-col="formItemLayout.wrapperCol">
                                    <a-input placeholder="地址"
                                             type="textarea"
                                             disabled
                                             v-model="mapModel.tempaddress" />
                                </a-form-model-item>

                            </a-tab-pane>
                        </a-tabs>
                    </a-form-model>
                </a-col>
            </a-row>
            <a-modal v-model="mapModelDisplay"
                     title="选择活动区域"
                     centered
                     okText="确定"
                     cancelText="取消"
                     width="900px"
                     height="700px"
                     @ok="confirmAddress">
                <div style="width: 100%; height: auto">
                    <div id="map_title">
                        请以当前
                        <a href="javascript:void(0)"
                           onclick="map_location();"
                           style="color: red; font-weight: 400; font-size: 20px">您的位置</a>为中心，选择本次活动范围。
                        <br />
                        <span>备注：可拖动红色中心标示，改变您当前位置</span>
                    </div>
                    <div id="map_sel">
                        <a-select :default-value="circlePath.radius"
                                  style="width: 120px"
                                  @change="radiusChange">
                            <a-select-option value="0">请选择活动区域</a-select-option>
                            <a-select-option value="5000">5公里</a-select-option>
                            <a-select-option value="10000">10公里</a-select-option>
                            <a-select-option value="15000">15公里</a-select-option>
                            <a-select-option value="20000">20公里</a-select-option>
                            <a-select-option value="30000">30公里</a-select-option>
                            <a-select-option value="50000">50公里</a-select-option>
                            <a-select-option value="100000">100公里</a-select-option>
                            <a-select-option value="-1">不限制</a-select-option>
                        </a-select>
                    </div>
                    <baidu-map class="bm-view"
                               ak="E8f3c743837925ce120eb93417f478db"
                               :center="mapModel.center"
                               :zoom="mapModel.zoom"
                               :scroll-wheel-zoom="true"
                               @ready="mapReady">
                        <bm-marker :position="mapModel.center"
                                   :dragging="true"
                                   @dragend="dragend">
                        </bm-marker>
                        <bm-circle :center="circlePath.center"
                                   :radius="circlePath.radius"
                                   :editing="circlePath.editing"
                                   stroke-color="blue"
                                   :stroke-opacity="0.5"
                                   :stroke-weight="2"></bm-circle>
                    </baidu-map>
                </div>
            </a-modal>
            <div v-if="QRCodeVisible">
                <QRCode :close="close" :ID="NewID" :hide="hide"></QRCode>
            </div>
        </div>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import BaiduMap from "vue-baidu-map/components/map/Map.vue";
    import { BmCircle } from "vue-baidu-map";
    import { BmMarker } from "vue-baidu-map";
    import util from "@/Plugin/util.js";
    import QRCode from "@/Views/MaterialModule/components/QRCode";
    export default {
        name: "Moment",
        data() {
            return {
                formItemLayout: {
                    labelCol: {
                        xs: { span: 12 },
                        sm: { span: 5 },
                    },
                    wrapperCol: {
                        xs: { span: 25 },
                        sm: { span: 24 },
                    },
                    labelColline: {
                        xs: { span: 12 },
                        sm: { span: 12 }
                    },
                    wrapperColline: {
                        xs: { span: 12 },
                        sm: { span: 12 },
                    }
                },
                Model: {
                    fileList: [],
                    Name: "",
                    Author: "",
                    Contents: "",
                    Summary: "",
                    ShareTime: "",
                    MaterialAward: {
                        BeginTime: "",
                        EndTime: "",
                        GPSLimit: false,
                        ReadSecond: 1,
                        ReadMoney: 0,
                        PersonMaxMoney: 0,
                        BudgetMoney: 0,
                        Lat: 36.062233,
                        Lng: 114.383176,
                        Radius: 10,
                        CenterPointAddress:
                            "以【 河南省安阳市文峰区弦歌大道 】为中心方圆【 10 】公里",
                        MaterialID: "",
                        Name: "",
                        CustomID: "",
                    },
                },
                ParamData: {
                    ProcessorName: "Image",
                    JsonDataParams: "",
                },
                Img: "",
                rules: {
                    Name: [{ message: "请输入标题", required: true, trigger: "blur" }],
                    Author: [{ message: "请输入作者", required: true, trigger: "blur" }],
                    Contents: [{ message: "请输入内容", required: true, trigger: "blur" }],
                    Summary: [{ message: "请输入摘要", required: true, trigger: "blur" }],
                    ShareTime: [{ message: "请选择分享时间", required: true, trigger: "blur" }],
                },
                Topic: {
                    ID: 10,
                },
                endOpen: false,
                mapModelDisplay: false,
                circlePath: {
                    center: {
                        lng: 114.383176,
                        lat: 36.062233,
                    },
                    radius: "10000",
                    editing: false,
                },
                mapModel: {
                    center: { lng: 114.383176, lat: 36.062233 },
                    zoom: 13,
                    tempaddress: "以【 河南省安阳市文峰区弦歌大道 】为中心方圆【 10 】公里",
                },
                QRCodeVisible: false,
                NewID: "",
            };
        },
        props: {
            //组件属性
            close: {
                type: Function,
                default: null,
            },
            ID: String,
            PushID: String,
            IDType: Number,
            disabled: Boolean,
        },
        methods: {
            //方法
            SubmitModel(formName, SubmitType) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        var self = this;
                        if (self.checkImage() && self.checkAward() && self.checkShareTime()) {
                            var op = {
                                url: "/MarketingModule/Material/EditMaterial",
                                data: {
                                    ID: self.ID,
                                    Name: self.Model.Name,
                                    Author: self.Model.Author,
                                    Summary: self.Model.Summary,
                                    Content: self.GetContent(),
                                    MaterialType: 0,
                                    Topic: self.Topic,
                                    TemplateID: 1,
                                    MaterialAward: self.Model.MaterialAward,
                                    ShareTime: self.Model.ShareTime,
                                },
                                OnSuccess: function (response) {
                                    console.log(formName + response);
                                    if (SubmitType == 0) {
                                        var con = self.ID == "0" ? "新增" : "编辑";
                                        self.$message.success(con + "成功");
                                        self.close(true);
                                    } else {
                                        self.NewID = response.data;
                                        self.UpdateState();
                                    }
                                },
                            };
                            http.Post(op);
                        } else {
                            return false;
                        }
                    }
                });
            },
            imgChange: function (info) {
                var self = this;
                if (info.file.status === "error") {
                    if (info.file.response && info.file.response.Message) {
                        this.$message.error(info.file.response.Message);
                    } else {
                        this.$message.error(`${info.file.name} 文件上传失败`);
                    }
                    return;
                }
                if (info.file.status === "done") {
                    info.file.url = info.file.response;
                }
                self.Model.fileList = info.fileList;
                self.Img =
                    self.Model.fileList.length > 0 ? self.Model.fileList[0].response : "";
            },
            GetContent: function () {
                var self = this;
                var Content = {
                    ID: self.ID,
                    ContextText: self.Model.Contents,
                    ImgTopic: self.Img,
                };
                return Content;
            },
            checkImage: function () {
                var rt = true;
                var self = this;
                if (self.Img == "") {
                    rt = false;
                    self.$message.error("请上传图片！");
                }
                return rt;
            },
            checkShareTime: function () {
                var rt = true;
                var self = this;
                if (Date.parse(self.Model.ShareTime) < new Date()) {
                    rt = false;
                    self.$message.error("请选择以后的时间！");
                }
                return rt;
            },
            checkAward: function () {
                var rt = true;
                var self = this;
                if (
                    self.Model.MaterialAward.ReadMoney > 0 ||
                    self.Model.MaterialAward.PersonMaxMoney > 0 ||
                    self.Model.MaterialAward.BudgetMoney > 0
                ) {
                    if (
                        self.Model.MaterialAward.BeginTime == "" &&
                        self.Model.MaterialAward.EndTime == ""
                    ) {
                        rt = false;
                        self.$message.error("请选择起止时间！");
                    }
                }
                return rt;
            },
            loadData: function () {
                var self = this;
                if (self.IDType == 0) {
                    var op = {
                        url: "/MarketingModule/Material/GetMaterialInfo",
                        data: {
                            id: self.ID,
                        },
                        OnSuccess: function (data) {
                            self.Model.Author = data.data.Author;
                            self.Model.MaterialAward.CustomID =
                                data.data.MaterialAward.CustomID;
                            if (self.ID != "0") {
                                self.Model.Name = data.data.Name;
                                self.Model.Summary = data.data.Summary;
                                self.Model.Contents = data.data.Content.ContextText;
                                var ImgTopic = data.data.Content.ImgTopic;
                                if (ImgTopic.indexOf('upload2') < 0) {
                                    ImgTopic = ImgTopic.replace(/upload\//g, 'upload2/');
                                }
                                if (ImgTopic != "" && ImgTopic != null) {
                                    self.Model.fileList = [
                                        {
                                            name: ImgTopic,
                                            response: ImgTopic,
                                            status: "done",
                                            uid: "0",
                                            url: ImgTopic,
                                        },
                                    ];
                                    self.Img = ImgTopic;
                                } else {
                                    self.Model.fileList = [];
                                }
                                if (
                                    data.data.MaterialAward.BeginTime != "/Date(-62135596800000)/"
                                ) {
                                    self.Model.MaterialAward.BeginTime = util.TimeStamptoDateTime(
                                        data.data.MaterialAward.BeginTime,
                                        "yyyy-MM-dd hh:mm:ss"
                                    );
                                }
                                if (
                                    data.data.MaterialAward.EndTime != "/Date(-62135596800000)/"
                                ) {
                                    self.Model.MaterialAward.EndTime = util.TimeStamptoDateTime(
                                        data.data.MaterialAward.EndTime,
                                        "yyyy-MM-dd hh:mm:ss"
                                    );
                                }
                                self.Model.MaterialAward.GPSLimit =
                                    data.data.MaterialAward.GPSLimit == 1 ? true : false;
                                if (self.Model.MaterialAward.GPSLimit == 1) {
                                    if (data.data.MaterialAward.Radius == -1) {
                                        self.circlePath.radius = data.data.MaterialAward.Radius.toString();
                                    } else {
                                        self.circlePath.radius = (
                                            data.data.MaterialAward.Radius * 1000
                                        ).toString();
                                    }
                                    self.circlePath.center.lat = data.data.MaterialAward.Lat;
                                    self.circlePath.center.lng = data.data.MaterialAward.Lng;
                                    self.mapModel.center.lat = data.data.MaterialAward.Lat;
                                    self.mapModel.center.lng = data.data.MaterialAward.Lng;
                                    self.mapModel.tempaddress =
                                        data.data.MaterialAward.CenterPointAddress;
                                }
                                self.Model.MaterialAward.ReadSecond =
                                    data.data.MaterialAward.ReadSecond;
                                self.Model.MaterialAward.ReadMoney =
                                    data.data.MaterialAward.ReadMoney;
                                self.Model.MaterialAward.PersonMaxMoney =
                                    data.data.MaterialAward.PersonMaxMoney;
                                self.Model.MaterialAward.BudgetMoney =
                                    data.data.MaterialAward.BudgetMoney;
                                self.Model.MaterialAward.MaterialID =
                                    data.data.MaterialAward.MaterialID;
                                self.Model.MaterialAward.Name = data.data.MaterialAward.Name;
                                self.Model.ShareTime = util.TimeStamptoDateTime(
                                    data.data.ShareTime,
                                    "yyyy-MM-dd hh:mm:ss"
                                );
                            }
                        },
                    };
                    http.Post(op);
                }
                if (self.IDType == 1) {
                    var op1 = {
                        url: "/MarketingModule/Material/GetMaterialQueueInfo",
                        data: {
                            QueueID: self.PushID,
                        },
                        OnSuccess: function (data) {
                            self.Model.Author = data.data.Author;
                            self.Model.Name = data.data.Name;
                            self.Model.Contents = data.data.Content.ContextText;
                            self.Model.Summary = data.data.Summary;
                            var ImgTopic = data.data.Content.ImgTopic;
                            if (ImgTopic.indexOf('upload2') < 0) {
                                ImgTopic = ImgTopic.replace(/upload\//g, 'upload2/');
                            }
                            if (ImgTopic != "" && ImgTopic != null) {
                                self.Model.fileList = [
                                    {
                                        name: ImgTopic,
                                        response: ImgTopic,
                                        status: "done",
                                        uid: "0",
                                        url: ImgTopic,
                                    },
                                ];
                                self.Img = ImgTopic;
                            } else {
                                self.Model.fileList = [];
                            }
                            self.Model.MaterialAward.Name = data.data.Name;
                            self.Model.MaterialAward.CustomID =
                                data.data.MaterialAward.CustomID;
                            self.Model.ShareTime = util.TimeStamptoDateTime(
                                data.data.ShareTime,
                                "yyyy-MM-dd hh:mm:ss"
                            );
                        },
                    };
                    http.Post(op1);
                }
                if (self.IDType == 2) {
                    var op2 = {
                        url: "/MarketingModule/Material/GetMaterialInfo",
                        data: {
                            id: self.PushID,
                        },
                        OnSuccess: function (data) {
                            self.Model.Author = data.data.Author;
                            self.Model.Name = data.data.Name;
                            self.Model.Summary = data.data.Summary;
                            self.Model.Contents = data.data.Content.ContextText;
                            var ImgTopic = data.data.Content.ImgTopic;
                            if (ImgTopic.indexOf('upload2') < 0) {
                                ImgTopic = ImgTopic.replace(/upload\//g, 'upload2/');
                            }
                            if (ImgTopic != "" && ImgTopic != null) {
                                self.Model.fileList = [
                                    {
                                        name: ImgTopic,
                                        response: ImgTopic,
                                        status: "done",
                                        uid: "0",
                                        url: ImgTopic,
                                    },
                                ];
                                self.Img = ImgTopic;
                            } else {
                                self.Model.fileList = [];
                            }
                            self.Model.MaterialAward.Name = data.data.Name;
                            self.Model.MaterialAward.CustomID =
                                data.data.MaterialAward.CustomID;
                            self.Model.ShareTime = util.TimeStamptoDateTime(
                                data.data.ShareTime,
                                "yyyy-MM-dd hh:mm:ss"
                            );
                        },
                    };
                    http.Post(op2);
                }
            },
            disabledStartDate(startValue) {
                const endValue = this.Model.MaterialAward.EndTime;
                if (!startValue || !endValue) {
                    return false;
                }
                return startValue.valueOf() > endValue.valueOf();
            },
            handleStartOpenChange(open) {
                if (!open) {
                    this.endOpen = true;
                }
            },
            disabledEndDate(endValue) {
                const startValue = this.Model.MaterialAward.BeginTime;
                if (!endValue || !startValue) {
                    return false;
                }
                return startValue.valueOf() >= endValue.valueOf();
            },
            handleEndOpenChange(open) {
                this.endOpen = open;
            },
            map_display: function () {
                var self = this;
                self.mapModelDisplay = true;
            },
            confirmAddress: function () {
                var self = this;
                self.mapModelDisplay = false;
                //确认地理位置
            },
            radiusChange: function (value) {
                var self = this;
                self.circlePath.radius = value;
                if (value == -1) {
                    self.Model.MaterialAward.Radius = value;
                } else {
                    self.Model.MaterialAward.Radius = value / 1000;
                }
            },
            mapReady: function ({ BMap, map }) {
                console.log(map);
                var self = this;
                self.gec = new BMap.Geocoder();
                self.Model.MaterialAward.Lng = self.mapModel.center.lng;
                self.Model.MaterialAward.Lat = self.mapModel.center.lat;
            },
            dragend: function (type) {
                var self = this;
                self.circlePath.center = type.point;
                self.Model.MaterialAward.Lng = type.point.lng;
                self.Model.MaterialAward.Lat = type.point.lat;
                self.gec.getLocation(type.point, function (rs) {
                    var addComp = rs.addressComponents;
                    var trainPlace =
                        addComp.province +
                        addComp.city +
                        addComp.district +
                        addComp.street +
                        addComp.streetNumber;
                    var content = trainPlace;
                    self.mapModel.tempaddress = content;
                    self.Model.MaterialAward.CenterPointAddress = content;
                });
            },
            name_change: function (item) {
                var self = this;
                self.Model.MaterialAward.Name = item.target.value;
            },
            hide: function () {
                var self = this;
                self.QRCodeVisible = false;
            },
            UpdateState() {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/UpdateStatus",
                    data: {
                        ID: self.NewID,
                        PublishStatus: 1
                    },
                    OnSuccess: function (data) {
                        console.log(data);
                        self.close(true);
                        self.$message.success("推送成功");
                    }
                };
                http.Post(op);
            },
        },
        computed: {
            //计算属性
        },
        watch: {
            //侦听属性
        },
        components: {
            BaiduMap,
            BmCircle,
            BmMarker,
            QRCode,
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
        mounted: function () {
            //生命周期，组装完成
            this.loadData();
        },
    };
</script>
<style scoped>
    .right-cont {
        background: #fff;
        height: 100%;
        overflow-y: auto;
    }

    .mask {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background: #f2f2f2;
        z-index: 9;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }

    .push-info {
        z-index: 9;
        height: 100%;
    }

    .phone-pre {
        text-align: center;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url("/Image/iphone.png") no-repeat center center/375px 776px;
    }

    .phone-frame {
        align-items: center;
      width: 350px;
        margin: auto;
        height: 685px;
        margin: auto;
        padding: 0 25px;
        text-align: left;
        position: relative;
        overflow-y: auto;
        background-size: 100%;
        color: #000000;
    }

    .bm-view {
        width: 100%;
        height: 600px;
    }

    #map_title {
        position: absolute;
        z-index: 9999;
        left: 23px;
        right: 23px;
        height: 60px;
        background-color: #09f;
        padding-top: 10px;
        padding-left: 20px;
        color: white;
    }

    #map_sel {
        position: absolute;
        z-index: 9999;
        right: 106px;
        top: 92px;
    }

    #map_btns {
        position: absolute;
        z-index: 9999;
        bottom: 30px;
        width: 100%;
    }

        #map_btns input[type="button"] {
            width: 120px;
            height: 45px;
            margin-top: 20px;
            color: #fff;
            border: none;
            border-radius: 30px;
            opacity: 0.8;
        }

    .map_save {
        background-color: green;
        margin-right: 40px;
    }

    .map_cancel {
        background-color: #f15b5b;
    }

    .solid-btn {
        background: #32a9ff;
        color: #fff !important;
        padding: 4px 18px;
        border: none;
        border-radius: 2px;
        transition: all 0.15s ease-in-out;
        margin-top: -3px;
    }

    .yj-storecon-template > div {
        height: 100px;
        width: 100px;
        overflow: hidden;
        text-align: center;
        flex-shrink: 0;
    }
</style>