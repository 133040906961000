import { render, staticRenderFns } from "./PackageList.vue?vue&type=template&id=08d8de26&scoped=true&MaterialType=5"
import script from "./PackageList.vue?vue&type=script&lang=js&MaterialType=5"
export * from "./PackageList.vue?vue&type=script&lang=js&MaterialType=5"
import style0 from "./PackageList.vue?vue&type=style&index=0&id=08d8de26&scoped=true&lang=css&MaterialType=5"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08d8de26",
  null
  
)

export default component.exports