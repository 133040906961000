<template>
    <a-row type="flex" justify="space-around" style="padding: 10px" :rules="rules2">
        <a-col :span="24">
            <a-form-model-item label="打卡周期"
                               labelAlign="left"
                               style="margin-bottom: 10px;"
                               :label-col="formItemLayout.labelCol"
                               :wrapper-col="formItemLayout.wrapperCol"
                               prop="customize">
                <div class="l_drive_create_time_item" v-on:click="customize==''?dayIndex=index:customize='',dayIndex=index"
                     :class="index == dayIndex && !customize ?'l_drive_create_time_item_actvie':''"
                     v-for="(item,index) in days"
                     :key="index">
                    <a-button v-on:click="Day(item)">{{item}}</a-button>
                </div>
                <div class="l_drive_create_time_item_customize" :class="customize ?'l_drive_create_time_item_actvie':''" style="margin-top:-40px;margin-left:80px">
                    <a-input type="number" v-model="customize" style="width:100px" name="day" placeholder="自定义"></a-input>
                    天
                </div>
            </a-form-model-item>

            <div class="line"></div>
        </a-col>
        <template>
            <el-radio v-model="radio" label="0">日常打卡</el-radio>
            <el-radio v-model="radio" label="1">单次打卡</el-radio>
        </template>
        <a-col :span="17">
            <a-form-model-item label="主题"
                               labelAlign="left"
                               style="margin-bottom: 10px;"
                               :label-col="formItemLayout.labelCol"
                               :wrapper-col="formItemLayout.wrapperCol" prop="InteractionName">
                <a-input placeholder="主题"
                         v-model="InteractionName"
                         style="outline: none"
                         onkeyup="this.value=this.value.replace(/\s+/g,'')"
                         :disabled="disabled"
                         :maxLength="50"
                         @change="name_change"></a-input>
            </a-form-model-item>
            <div class="line"></div>
        </a-col>

        <a-col style="margin: 0 auto;" :span="5">
        </a-col>

        <a-col :span="24">
            <a-form-model-item label="规则"
                               labelAlign="left"
                               style="margin-bottom: 10px"
                               :label-col="formItemLayout.labelCol"
                               :wrapper-col="formItemLayout.wrapperCol" prop="InteractionSummary">
                <a-input placeholder="请输入内容"
                         type="textarea"
                         rows="6"
                         v-model="InteractionSummary"
                         :maxLength="200"
                         @change="summary_change"
                         onkeyup="this.value=this.value.replace(/\s+/g,'')"
                         :disabled="disabled"></a-input>
                <div style="position:absolute; margin-left:85%;margin-top:-40px;">{{InteractionSummary.length==0?1:InteractionSummary.length}}/200字</div>
            </a-form-model-item>
        </a-col>

        <a-col :span="24">
            <a-form-model-item label="文案"
                               labelAlign="left"
                               style="margin-bottom: 10px"
                               :label-col="formItemLayout.labelCol"
                               :wrapper-col="formItemLayout.wrapperCol">
                <a-input placeholder="请输入内容"
                         type="textarea"
                         rows="6"
                         v-model="InteractionCopywriting"
                         :maxLength="200"
                         @change="copywriting_change"
                         onkeyup="this.value=this.value.replace(/\s+/g,'')"
                         :disabled="disabled"></a-input>
                <div style="position:absolute; margin-left:85%;margin-top:-40px;">{{InteractionCopywriting.length==0?1:InteractionCopywriting.length}}/200字</div>
            </a-form-model-item>
        </a-col>

        <a-button @click="Create()" style="display:none">创建互动</a-button>
    </a-row>
</template>

<script>
    import http from "@/Plugin/Http.js";
    export default {
        name: "InterAction",
        data() {
            return {
                txtVal: 0,
                InteractionName: "",
                InteractionSummary: "",
                InteractionStartTime: "",
                InteractionEndTime: "",
                InteractionCopywriting: "",
                days: [
                    "14天",
                    "30天",
                    "180天",
                ],
                NewID: "",
                SumDay: "14",
                customize: '',
                formItemLayout: {
                    labelCol: {
                        xs: { span: 12 },
                        sm: { span: 12 },
                    },
                    wrapperCol: {
                        xs: { span: 25 },
                        sm: { span: 24 },
                    },
                    labelColline: {
                        xs: { span: 12 },
                        sm: { span: 12 },
                    },
                    wrapperColline: {
                        xs: { span: 12 },
                        sm: { span: 12 },
                    },
                },
                dayIndex: "",
                rules2: {
                    InteractionName: [{ message: "请输入互动主题", required: true, trigger: "blur" }],

                    InteractionSummary: [{ message: "请输入互动内容", required: true, trigger: "blur" }],
                },
                radio: '0',//领取类型
            };
        },
        props: {//组件属性
            closeDialog: {
                type: Function,
                default: null
            },
            MaterialType: Number,
            disabled: Boolean,
        },
        methods: {//方法


            checkInterActionName: function () {
                var rt = true;
                var self = this;
                if (self.InteractionName == "") {
                    rt = false;
                    self.$message.error("请填写打卡主题！");
                }
                return rt;
            },
            checkInterActionSummary: function () {
                var rt = true;
                var self = this;
                if (self.InteractionSummary == "") {
                    rt = false;
                    self.$message.error("请填写打卡规则！");
                }
                return rt;
            },

            name_change: function (item) {
                var self = this;
                self.InteractionName = self.InteractionName.length;
                self.InteractionName = item.target.value;
            },

            summary_change: function (item) {
                var self = this;
                self.InteractionSummary = self.InteractionSummary.length;
                self.InteractionSummary = item.target.value;
            },

            copywriting_change: function (item) {
                var self = this;
                self.InteractionCopywriting = item.target.value;
            },

            Create: function (e) {
                var self = this;
                if (this.customize == "") {
                    this.EndTime = this.GetEndTime(this.SumDay);
                } else {
                    this.EndTime = this.GetEndTime(this.customize);
                }
                this.StartTime = this.GetStartTime();
                //if (self.checkInterActionName() && self.checkInterActionSummary()) {
                    var op = {
                        url: "/MarketingModule/MKTPunchClock/AddInterActionInfo",
                        data: {
                            Name: self.InteractionName,
                            Summary: self.InteractionSummary,
                            StartTime: this.StartTime,
                            EndTime: this.EndTime,
                            MaterialID: e,
                            Copywriting: self.InteractionCopywriting,
                            ClockInModel:self.radio,
                        },
                        OnSuccess: function (response) {
                            //self.savedata(response.data);
                            self.NewID = response.data;
                            self.loading = false;
                        },
                    };
                    http.Post(op);
                //} else {
                //    return false;
                //}
            },
            //获取开始时间
            GetStartTime() {
                var T = new Date();
                var Month = (T.getMonth() + 1);
                var year = T.getFullYear();
                var day = T.getDate();
                if (Month < 10)
                    Month = "0" + Month;
                if (day < 10)
                    day = "0" + day;
                //var Seconds = T.getMinutes();
                //var Hours = T.getHours();
                //var Seconds = T.getSeconds();
                //var Milliseconds = T.getMilliseconds();
                //var Minutes = T.getMinutes();
                //if (Minutes < 10)
                //    Minutes = "0" + Minutes;
                //if (Seconds < 10)
                //    Seconds = "0" + Seconds;
                //if (Hours < 10)
                //    Hours = "0" + Hours;
                var Hours = "00";
                var Minutes = "00";
                var Seconds = "00";
                var Milliseconds = "000";
                var time = year + "-" + Month + "-" + day + " " + Hours + ":" + Minutes + ":" + Seconds + "." + Milliseconds;
                return time;
            },
            //获取结束时间
            GetEndTime(e) {
                var T = new Date();
                var Month = (T.getMonth() + 1);
                var year = T.getFullYear();
                var day = T.getDate();
                if (Month < 10)
                    Month = "0" + Month;
                if (day < 10)
                    day = "0" + day;
                var oldtiem = year + "-" + Month + "-" + day;
                var da = this.getNewData(oldtiem, e);
                //var Seconds = T.getMinutes();
                //var Hours = T.getHours();
                //var Seconds = T.getSeconds();
                //var Milliseconds = T.getMilliseconds();
                //var Minutes = T.getMinutes();
                //if (Minutes < 10)
                //    Minutes = "0" + Minutes;
                //if (Seconds < 10)
                //    Seconds = "0" + Seconds;
                //if (Hours < 10)
                //    Hours = "0" + Hours;
                var Hours = "23";
                var Minutes = "59";
                var Seconds = "59";
                var Milliseconds = "000";
                return (da + " " + Hours + ":" + Minutes + ":" + Seconds + "." + Milliseconds);
                /* console.log(da);*/
            },
            //计算结束时间
            getNewData(dateTemp, days) {
                var nDate = new Date(dateTemp);
                var millSeconds = Math.abs(nDate) + (days * 24 * 60 * 60 * 1000);
                var rDate = new Date(millSeconds);
                var year = rDate.getFullYear();
                var month = rDate.getMonth() + 1;
                var date = rDate.getDate();
                if (date < 10)
                    date = "0" + date;
                date = date - 1;
                if (date == 0) {
                    month = month - 1;
                    date = new Date(year, month, 0).getDate();
                }
                if (month < 10)
                    month = "0" + month;
                return (year + "-" + month + "-" + date);
            },
            Day(e) {
                this.SumDay = e.replace('天', '');
                console.log(this.SumDay);
            },
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };

</script>