<template>
    <div>
        <div v-if="MaterialType==0">
            <Moment :close="closeDetail" :ID="ID" :PushID="PushID" :IDType="IDType" :disabled="disabled"></Moment>
        </div>
        <div v-if="MaterialType==1">
            <Extension :close="closeDetail" :ID="ID" :PushID="PushID" :IDType="IDType" :disabled="disabled"></Extension>
        </div>
        <div v-if="MaterialType==3">
            <H5 :close="closeDetail" :ID="ID" :PushID="PushID" :IDType="IDType" :disabled="disabled"></H5>
        </div>
        <div v-if="MaterialType==5">
            <Poster :close="closeDetail" :ID="ID" :PushID="PushID" :IDType="IDType" :disabled="disabled"></Poster>
        </div>
    </div>
</template>
<script>
    import Moment from '@/Views/MaterialModule/components/Moment';
    import Extension from '@/Views/MaterialModule/components/Extension';
    import H5 from '@/Views/MaterialModule/components/NewH5';
    import Poster from '@/Views/MaterialModule/components/NewPoster';
    export default {
        name: "Detail",
        data() {
            return {
            };
        },
        props: {//组件属性
            closeDetail: {
                type: Function,
                default: null
            },
            MaterialType: Number,
            ID: String,
            PushID: String,
            IDType: Number,
            disabled: Boolean,
        },
        methods: {//方法
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
            Moment,
            Extension,
            H5,
            Poster
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>