<template>
  <div class="mask">
    <div class="push-info" :title="IDType">
      <a-row :gutter="10" style="height: 100%">
        <a-col :span="14" class="phone-pre">
          <div class="phone-frame" style="padding-top: 20px; z-index: 10">
            <h2 style="font-weight: bold">{{ Model.Name }}</h2>
            <p style="color: #777">{{ Model.Author }}</p>
            <br />
            <div class="contents-p" style="word-break: break-all" v-html="Model.Contents"></div>
            <!--<img :src="Img" style="width: 100%; object-fit: cover" />-->
          </div>
        </a-col>
        <a-col :span="10" class="right-cont">
          <a-form-model
            layout="horizontal"
            style="  height: auto;text-align:left;"
            :rules="rules"
            ref="ruleForm"
            :model="Model"
          >
            <a-tabs default-active-key="0">
              <a-tab-pane key="0" tab="编辑" style="padding:0 20px">
                <div
                  style="position:absolute;right:10px;top:5px;z-index:1111;display:grid; grid-template-columns:repeat(3,1fr);gap:10px; "
                >
                  <a-button
                    type="link"
                    @click="SubmitModel('ruleForm', 0)"
                    :loading="loading"
                    icon="eye"
                  >预览</a-button>

                  <a-button
                    type="primary"
                    @click="SubmitModel('ruleForm', 1)"
                    :disabled="disabled"
                    :loading="loading"
                  >推送</a-button>

                  <a-button @click="close()">取消</a-button>
                </div>
                <a-row type="flex" justify="space-around" style=" ">
                  <a-col :span="17">
                    <a-form-model-item
                      label="文章标题"
                      labelAlign="left"
                      style="margin-bottom: 10px"
                      :label-col="formItemLayout.labelCol"
                      :wrapper-col="formItemLayout.wrapperCol"
                      prop="Name"
                    >
                      <a-input
                        placeholder="请在这里输入标题"
                        v-model="Model.Name"
                        style="outline: none"
                        onkeyup="this.value=this.value.replace(/\s+/g,'')"
                        :disabled="disabled"
                        :maxLength="50"
                        @change="name_change"
                      ></a-input>
                    </a-form-model-item>
                    <div class="line"></div>
                    <a-form-model-item
                      label="分享描述"
                      labelAlign="left"
                      style="margin-bottom: 10px"
                      :label-col="formItemLayout.labelCol"
                      :wrapper-col="formItemLayout.wrapperCol"
                      prop="Summary"
                    >
                      <a-input
                        placeholder="分享描述会在转发链接外的场景显露，帮助用户快速了解内容"
                        type="textarea"
                        v-model="Model.Summary"
                        onkeyup="this.value=this.value.replace(/\s+/g,'')"
                        :maxLength="100"
                        :disabled="disabled"
                        @change="summary_change"
                        style="outline: none"
                      ></a-input>
                      <div
                        style="position:absolute; margin-left:85%;margin-top:-40px;"
                      >{{Model.Summary.length==0?1:Model.Summary.length}}/{{maxLength}}字</div>
                    </a-form-model-item>
                  </a-col>
                  <a-col style="margin: 0 auto" :span="5">
                    <a-upload
                      name="image"
                      :data="ParamData"
                      list-type="picture-card"
                      class="avatar-uploader"
                      action="/FileServer/Upload"
                      :fileList="Model.fileList"
                      :beforeUpload="beforeUpload"
                      @change="imgChange"
                      :disabled="disabled"
                    >
                      <div v-if="Model.fileList.length < 1">
                        <a-icon type="plus" />
                        <div class="ant-upload-text">缩略图</div>
                      </div>
                    </a-upload>
                  </a-col>
                </a-row>
                <a-form-model-item
                  label="内容正文"
                  labelAlign="left"
                  style="margin-bottom: 10px"
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  prop="Contents"
                >
                  <!--<Rich v-bind:value.sync="Model.Contents" rows="800"  :disabled="disabled"></Rich>-->
                  <div :class="towclass">
                    <iframe
                      frameborder="0"
                      style="width:100%;height:100%"
                      src="/js2/wxEditor-master/UEditor.html"
                      id="myEditorFrame"
                      name="myEditorFrame"
                    ></iframe>
                  </div>
                </a-form-model-item>
                <div :class="UEditormask"></div>
                <a-row style="display:none;">
                  <a-col :span="24">
                    <a-form-model-item
                      label="分享时间"
                      labelAlign="left"
                      :label-col="formItemLayout.labelCol"
                      :wrapper-col="formItemLayout.wrapperColline"
                      prop="ShareTime"
                    >
                      <a-date-picker
                        v-model="Model.ShareTime"
                        style="width: 100%"
                        show-time
                        type="date"
                        format="YYYY-MM-DD HH:mm"
                        placeholder="选择开始时间"
                        :disabled="disabled"
                      />
                    </a-form-model-item>
                  </a-col>
                </a-row>
              </a-tab-pane>
            </a-tabs>
          </a-form-model>
        </a-col>
      </a-row>
      <div v-if="QRCodeVisible">
        <QRCode :close="close" :ID="NewID" :hide="QRCodehide" :QRCodeType="QRCodeType"></QRCode>
      </div>
    </div>
  </div>
</template>
<script>
import http from "@/Plugin/Http.js";
import util from "@/Plugin/util.js";
import imgapi from "@/Plugin/img.js";
//保存时弹出的确定框
import QRCode from "@/Views/MaterialModule/components/QRCode";
export default {
  name: "Extension",
  data() {
    return {
      Model: {
        fileList: [],
        Name: "",
        Author: "",
        Contents: "",
        Summary: "",
        ShareTime: ""
      },
      Img: "",
      ParamData: {
        ProcessorName: "Image",
        JsonDataParams: ""
      },
      rules: {
        Name: [{ message: "请输入标题", required: true, trigger: "blur" }],
        Author: [{ message: "请输入作者", required: true, trigger: "blur" }],
        Contents: [{ message: "请输入内容", required: true, trigger: "blur" }],
        Summary: [{ message: "请输入摘要", required: true, trigger: "blur" }],
        ShareTime: [
          { message: "请选择分享时间", required: true, trigger: "blur" }
        ]
      },
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 24 }
        },
        labelColline: {
          xs: { span: 12 },
          sm: { span: 12 }
        },
        wrapperColline: {
          xs: { span: 12 },
          sm: { span: 12 }
        }
      },
      Topic: {
        ID: 10
      },
      endOpen: false,
      QRCodeVisible: false,
      NewID: "",
      QRCodeType: 0,
      PromoteendOpen: false,
      SelectType: "checkbox",
      loading: false,
      towclass: "divfull2",
      UEditormask: "UEditormask2",
      IDTypes: 0,
      ID: "",
      maxLength: 100,
      OrderInfoID: ""
      // thispage:this.$route.query.page,
      // c_type:this.$route.query.c_type,
      // r_type:this.$route.query.r_type,
      // s_name:this.$route.query.s_name,
    };
  },
  props: {
    //组件属性
    //close: {
    //    type: Function,
    //    default: null,
    //},
    //ID: String,
    PushID: String,
    IDType: Number,
    disabled: Boolean
  },
  methods: {
    close() {
      this.$router.go(-1);
    },
    name_change: function(item) {
      var self = this;
      self.Name = self.Model.Name.length;
      self.Model.Name = item.target.value;
    },
    summary_change: function(item) {
      var self = this;
      self.Summary = self.Model.Summary.length;
      self.Model.Summary = item.target.value;
    },
    //方法
    imgChange: function(info) {
      var self = this;
      if (info.file.status === "error") {
        if (info.file.response && info.file.response.Message) {
          this.$message.error(info.file.response.Message);
        } else {
          this.$message.error(`${info.file.name} 文件上传失败`);
        }
        return;
      }
      if (info.file.status === "done") {
        info.file.url = info.file.response;
      }
      self.Model.fileList = info.fileList;
      self.Img =
        self.Model.fileList.length > 0 ? self.Model.fileList[0].response : "";
    },
     beforeUpload: function(file) {
       var self=this;
       if(file.size/1024 > 1024)
       {
          self.compress(file);
          return false;
       }else{
         return true;
       }
    },
    checkImage: function() {
      var rt = true;
      var self = this;
      if (self.Img == "") {
        rt = false;
        self.$message.error("请上传缩略图！");
      }
      return rt;
    },
    SubmitModel(formName, SubmitType) {
      var self = this;
      self.QRCodeType = SubmitType;
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (self.checkImage()) {
            self.loading = true;
            if (self.OrderInfoID != 0 || self.OrderInfoID != 10) {
              if (self.checkImage()) {
                self.loading = true;
                var op1 = {
                  url: "/MarketingModule/Material/EditMaterial",
                  data: {
                    ID: self.NewID == "" ? self.ID : self.NewID,
                    Name: self.Model.Name,
                    Author: self.Model.Author,
                    Summary: self.Model.Summary,
                    Content: self.GetContent(),
                    MaterialType: 1,
                    Topic: self.OrderInfoID,
                    TemplateID: 2,
                    Logo: self.Img,
                    ShareTime: self.Model.ShareTime,
                    CreateType: 1
                  },
                  OnSuccess: function(response) {
                    console.log(formName + response);
                    self.NewID = response.data;
                    self.loading = false;
                    if (!self.disabled) {
                      self.$message.success("保存成功");
                    }
                    self.QRCodeVisible = true;
                  }
                };
                http.Post(op1);
              } else {
                return false;
              }
            } else {
              var op = {
                url: "/MarketingModule/Material/EditMaterial",
                data: {
                  ID: self.NewID == "" ? self.ID : self.NewID,
                  Name: self.Model.Name,
                  Author: self.Model.Author,
                  Summary: self.Model.Summary,
                  Content: self.GetContent(),
                  MaterialType: 1,
                  Topic: self.Topic,
                  TemplateID: 2,
                  Logo: self.Img,
                  ShareTime: self.Model.ShareTime,
                  CreateType: 0
                },
                OnSuccess: function(response) {
                  console.log(formName + response);
                  self.NewID = response.data;
                   localStorage.setItem("MaterialID",self.NewID);
                  self.loading = false;
                  if (!self.disabled) {
                    self.$message.success("保存成功");
                  }
                  self.QRCodeVisible = true;
                }
              };
              http.Post(op);
            }
          }
        }
      });
    },
    GetContent: function() {
      var self = this;
      var Content = { ID: self.ID, ContextText: self.Model.Contents };
      return Content;
    },
    loadData: function() {
      var self = this;
      self.Model.ShareTime = util.formatDate3(new Date());
      if (self.IDTypes == 0) {
        var op = {
          url: "/MarketingModule/Material/GetMaterialInfo",
          data: {
            id: self.ID
          },
          OnSuccess: function(data) {
            self.Model.Author = data.data.Author;
            if (self.ID != "0") {
              self.Model.Name = data.data.Name;
              self.Model.Summary = data.data.Summary;
              //获取文本内容
              var htext = data.data.Content.ContextText;
              self.Model.Contents =
                (htext && htext.replace(/upload\//g, "upload2/")) || "";
              var ImgTopic = data.data.Logo;
              if (ImgTopic.indexOf("upload2") < 0) {
                ImgTopic = ImgTopic.replace(/upload\//g, "upload2/");
              }
              if (ImgTopic != "" && ImgTopic != null) {
                self.Model.fileList = [
                  {
                    name: ImgTopic,
                    response: ImgTopic,
                    status: "done",
                    uid: "0",
                    url: ImgTopic
                  }
                ];
                self.Img = ImgTopic;
              } else {
                self.Model.fileList = [];
              }
            }

            self.chuanzhi();
          }
        };
        http.Post(op);
      }

      if (self.IDType == 1) {
        var op1 = {
          url: "/MarketingModule/Material/GetMaterialQueueInfo",
          data: {
            QueueID: self.PushID
          },
          OnSuccess: function(data) {
            self.Model.Author = data.data.Author;
            self.Model.Name = data.data.Name;
            self.Model.Summary = data.data.Summary;
            self.OrderInfoID = data.data.Topic.ID;
            var htext = data.data.Content.ContextText;
            self.Model.Contents =
              (htext && htext.replace(/upload\//g, "upload2/")) || "";
            self.Model.Contents = data.data.Content.ContextText;
            var ImgTopic = data.data.Logo;
            if (ImgTopic != "" && ImgTopic != null) {
              self.Model.fileList = [
                {
                  name: ImgTopic,
                  response: ImgTopic,
                  status: "done",
                  uid: "0",
                  url: ImgTopic
                }
              ];
              self.Img = ImgTopic;
            } else {
              self.Model.fileList = [];
            }
            self.chuanzhi();
          }
        };
        http.Post(op1);
      }
    },

    //接收子页面传递的值
    SubpageValue: function() {
      var self = this;
      var child =
        document.getElementById("myEditorFrame").contentWindow || null;
      var content = child && child.$ueditor && child.$ueditor.Tex();
      self.Model.Contents = content;
      console.log(content);
    },
    //向子页面穿值
    chuanzhi: function() {
      var self = this;
      var dt = self.Model.Contents;
      var child =
        document.getElementById("myEditorFrame").contentWindow || null;
      var iMax = 10;
      var matchload = function() {
        (child.$ueditor &&
          (console.log("向子页面传值AAA", dt),
          child.$ueditor.init(dt),
          child.$ueditor.onchange(self.onUEditorChange),
          child.$ueditor.onfull(self.onUEditorFull),
          (iMax = 0),
          1)) ||
          ((iMax -= 1), 1);
        iMax && setTimeout(matchload, 500);
      };
      setTimeout(matchload, 500);
    },
    onUEditorChange: function(text) {
      this.Model.Contents = text;
      console.log("父页面收到change:", text);
    },
    onUEditorFull: function(isfull) {
      console.log("isfull", isfull);
      this.towclass = (isfull && "divfull") || "divfull2";
      this.UEditormask = (isfull && "UEditormask") || "UEditormask2";
    },
    hide: function() {
      var self = this;
      self.QRCodeVisible = false;
    },
    QRCodehide: function() {
      var self = this;
      self.QRCodeVisible = false;
    },
    handleUpload: function(info) {
      var formData = new FormData();
      var self=this;
      formData.append("image", info.file);
      formData.append("JsonDataParams", "");
      formData.append("ProcessorName", "Image");
      var op = {
        url: "/FileServer/Upload",
        method: 'post',
        processData: false,
        dataType: "json",
        data: formData,
        OnSuccess: function(data) {
          console.log(data);
          self.Img=data.data;
        }
      };
      http.Post(op);
    },
    compress(info) {//图片压缩
      var self = this;
      var reader = new FileReader();
      //拿到本次上传的文件对象
      //如果有需要，可以判断file.type是否是图片，如果不是照片就返回
      //file.type.indexOf("image/") 如果返回0 就是图片格式，如果不是，就是其他文件格式
      if (info.type.indexOf("image/") == -1) {
        //不是图片
        //清除不符合图片格式的文件
        info.value = "";
        return false;
      } else {
        //是图片
        //为了操作图片，需要 FileReader 这个js内置的工具对象，对图片进行读取
        //读取照片,将其读出base64的格式
        reader.readAsDataURL(info);
        //读出过程，不是一瞬间完成的，需要一点时间完成。所以，需要进行异步处理，不然可能会出现图片还没读完，就跳到下一行语句的情况
        reader.onload = function() {
          //拿到具体的base64的数据
          var img_base64 = reader.result;
          //获取原图的宽度和高度，需要使用 Image对象
          var img = new Image();
          //将base64的数据  装载到image对象中，用来拿到原图大小
          img.src = img_base64;
          //图片装载也需要时间，所以需要异步进行
          img.onload = function() {
            //设置要求压缩后的最大值的宽或者高
            var max_width = 800;
            var max_height = 800;
            var image_width;
            var image_height;
              //判断是横图 还是竖图
              if (img.width / img.height >= 1) {
                //横图或者正方形图
                console.log("横图");
                if (img.width >= max_width) {
                  //设置画布的宽和高
                  image_width = max_width;
                  image_height = Math.round(
                    img.height / (img.width / max_width)
                  );
                }
              } else {
                //竖图
                console.log("竖图");
                if (img.height >= max_height) {
                  //设置画布的宽和高
                  image_width = Math.round(
                    img.width / (img.height / max_height)
                  );
                  image_height = max_height;
                }
              }
              //新建HTML5的CANVAS 元素，用来对图片进行压缩
              var canvas = document.createElement("canvas");
              canvas.width = image_width;
              canvas.height = image_height;
              //获取canvas 2d的操作对象
              var context = canvas.getContext("2d");
              //将图片绘制到canvas画布上面，和原图一样的宽高
              context.drawImage(img, 0, 0, image_width, image_height);
              //此处的res就是压缩后的Base64编码的图片资源，默认模糊程度设置为0.75，如果需要PNG图片，可以改成PNG。填写jpeg的话，默认是jifi格式，如果选择webp格式，图片会更加小，谷歌发明的。但是对各个浏览器的兼容性不是特别好
              var res = canvas.toDataURL("image/jpeg", 0.75);
              //展示在网页上，如果需要上传，可以再做操作
              var files = imgapi.dataURLtoFile(res);
              var Filename = info.name;
              var Newfile = new File([files], Filename, {
                name: Filename,
                type: info.type
              });
              info.file = Newfile;
              self.handleUpload(info);
            //}
          };
        };
      }
    }
  },
  computed: {
    //计算属性
  },
  watch: {
    //侦听属性
  },
  components: {
    //组件
    QRCode
  },
  beforeCreate: function() {
    //生命周期，创建前
  },
  created: function() {
    //生命周期，创建完成
  },
  beforeMount: function() {
    //生命周期，组装前
  },
  mounted: function() {
    //生命周期，组装完成
    this.ID = this.$route.query.ID;
    //this.IDType = this.$route.query.IDType;
    this.loadData(this.$route.params);
  },
  beforeUpdate: function() {
    //生命周期，data更新前
  },
  updated: function() {
    //生命周期，data更新完成
  },
  beforeDestroy: function() {
    //生命周期，销毁前
    //生命周期，销毁前
  },
  destroyed: function() {
    //生命周期，销毁完成
  }
};
</script>
<style scoped>
.right-cont {
  background: #fff;
  height: 100%;
  overflow-y: auto;
}

.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: #f7f8f9;
  z-index: 9;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.push-info {
  z-index: 9;
  height: 100%;
}

.phone-pre {
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f3f7;
}

.phone-frame {
  align-items: center;
  width: 440px;
  height: 782px;
  margin: auto;
  overflow-x: hidden;
  padding: 0 10px;
  text-align: left;
  position: relative;
  overflow-y: auto;
  background-size: 100%;
  background: #fff;
  color: #000000;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 15px;
}

.iframe-p {
  width: 345px;
  padding: 35px 25px;
  height: 80vh;
  margin: 50px auto;
  text-align: left;
  overflow-y: auto;
  color: #000000;
}

.bm-view {
  width: 100%;
  height: 600px;
}

#map_title {
  position: absolute;
  z-index: 9999;
  left: 23px;
  right: 23px;
  height: 60px;
  background-color: #09f;
  padding-top: 10px;
  padding-left: 20px;
  color: white;
}

#map_sel {
  position: absolute;
  z-index: 9999;
  right: 106px;
  top: 92px;
}

#map_btns {
  position: absolute;
  z-index: 9999;
  bottom: 30px;
  width: 100%;
}

#map_btns input[type="button"] {
  width: 120px;
  height: 45px;
  margin-top: 20px;
  color: #fff;
  border: none;
  border-radius: 30px;
  opacity: 0.8;
}

.map_save {
  background-color: green;
  margin-right: 40px;
}

.map_cancel {
  background-color: #f15b5b;
}

.solid-btn {
  background: #32a9ff;
  color: #fff !important;
  padding: 4px 18px;
  border: none;
  border-radius: 2px;
  transition: all 0.15s ease-in-out;
  margin-top: -3px;
}

.yj-storecon-template > div {
  height: 100px;
  width: 100px;
  overflow: hidden;
  text-align: center;
  flex-shrink: 0;
}

::v-deep .ql-align-justify {
  text-align: justify;
}
::v-deep .contents-p section {
  max-width: 100% !important;
}
::v-deep .contents-p img {
  max-width: 100% !important;
}
.contents-p > p {
  clear: both;
}
.content {
  min-height: 250px;
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.content {
  margin: 15px 20px 15px 15px;
  padding-top: 0;
}

.form-search {
  display: block;
  list-style-type: disc;
  padding-left: 0;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
}

.box {
  border-top: none;
  box-shadow: none;
}

.right-btn {
  margin-top: 18px;
}

.line-btn {
  border: 1px solid #32a9ff;
  color: #32a9ff;
  padding: 7px 15px;
  background: #fff;
  transition: all 0.15s ease-in-out;
}

.gray-line-btn {
  border: 1px solid #e5e5e5;
  color: #666;
  padding: 7px 15px;
  background: #fff;
  transition: all 0.15s ease-in-out;
  margin-left: 20px;
}

.gray-line-btn {
  cursor: pointer;
}

.solid-btn {
  background: #32a9ff;
  color: #fff !important;
  padding: 4px 18px;
  border: none;
  border-radius: 2px;
  transition: all 0.15s ease-in-out;
  margin-top: -3px;
}

.solid-btn:hover {
  background: #1b99f5;
  cursor: pointer;
  color: #fff;
}

.num {
  font-size: 26px;
  color: #32a9ff;
  padding: 5px;
  font-weight: 500;
}

.inners {
  padding: 10px;
  border: 1px solid #f3f4f5;
}

.search {
  overflow: hidden;
  background: #fbfbfb;
  margin: 10px auto;
  padding: 10px;
}

.line-btn:hover {
  background: #32a9ff;
  color: #fff;
  border-radius: 2px;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
}

.form-search {
  display: block;
  list-style-type: disc;
  padding-left: 0;
}

.mtb5 {
  margin: 5px 0;
}

.mtb10 {
  margin: 10px 0;
}

.mtb20 {
  margin: 20px 0;
}

.tr-middle td {
  vertical-align: middle;
}

body {
  background: #f3f5f5;
  font-family: Arial, Helvetica, sans-serif, "Microsoft YaHei", "ËÎÌå";
  font-size: 15px;
}

.sys-name {
  color: #00a2e9;
  margin: 0;
  padding: 0;
  font-size: 25px;
  font-weight: 500;
  height: 68px;
  line-height: 68px;
}

.right-opt {
  height: 68px;
  line-height: 68px;
  cursor: pointer;
}

.top-top {
  margin-bottom: 10px;
  background: #fff;
  border-bottom: solid 1px #e0e5e5;
}

.top-center {
  max-width: 1160px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.content-wrapper {
  background: #fff;
}

.content {
  margin: 15px 20px 15px 15px;
  padding-top: 0;
}

.box-header {
  border-bottom: 1px solid #f3f5f5;
  padding: 0 10px;
}

.box-header > .box-title {
  height: 62px;
  line-height: 62px;
}

.top-center a[href] {
  color: #32a9ff;
}

a {
  color: #32a9ff;
}

.pager li > a {
  border: none;
}

.main-right-search {
  padding: 20px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  background-color: #fbfbfb;
  padding-top: 0;
}

.form-search li {
  float: left;
  overflow: hidden;
  margin-right: 17px;
  width: 350px;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  border: solid 1px #e0e5e5;
  border-radius: 4px;
  -moz-border-radius: 4px;
  list-style: none;
  margin-top: 8px;
  margin-bottom: 0;
  padding: 0;
}

.form-search li label {
  float: left;
  width: 110px;
  color: #666;
  text-align: center;
  border-right: solid 1px #e0e5e5;
}

.form-search li input {
  float: left;
  border: none;
  background: none;
  outline: none;
  text-indent: 6px;
  -webkit-appearance: none;
  background-color: #ffffff;
  width: 235px;
  height: 26px;
  color: #333333;
  line-height: 28px;
}

.form-search-margin {
  margin-top: 12px;
}

.form-search li .inline-text {
  padding-left: 5px;
}

.pager {
  padding-left: 0;
  margin: 20px 0;
  text-align: center;
  list-style: none;
}

.pager li {
  display: inline;
}

.pager ul li {
  list-style: none outside none;
}

.pager li > a {
  border: none;
}

.pager li > a,
.pager li > span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border-radius: 15px;
}

.table {
  width: 100%;
  margin-bottom: 20px;
}

.divfull {
  position: fixed;
  width: 90%;
  height: 90%;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  left: 10px;
  z-index: 10000;
}

.divfull2 {
  width: 700px;
  height: 600px;
}

.UEditormask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  display: block;
  left: 0px;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
}

.UEditormask2 {
  display: none;
}
</style>