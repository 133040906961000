<template>
  <div style="min-height:100%; background: #fff;padding: 0px 20px 0;margin: 0;">
    <a-layout-content style="height: 100%; width: 100%;background:#fff">
      <div style="display:none">
        <a-row :gutter="[16, 16]">
          <a-col :span="4" v-for="info in tableData" :key="info.ID">
            <a-card hoverable :bordered="true" style="height: 320px">
              <img
                slot="cover"
                :src="Getsrc(info)"
                class="thumb-img1"
                @click="Store_PackageDetail(info.ID)"
              />
              <a-card-meta :title="info.Name">
                <template slot="description">
                  <div @click="Store_PackageDetail(info.ID)">
                    <small style=" float:right">已订阅{{ info.SubscriptionCount }}</small>
                    <p class="mintro" v-html="info.Summary"></p>
                    <p class="mintro2">{{ GetPackageRule(info) }}</p>
                  </div>
                  <p class="option">
                    <a-button
                      :type=" info.Subscriptionbool ? 'danger' : ''"
                      @click="EditSubscription(info)"
                    >
                      {{
                      info.Subscriptionbool ? "取消订阅" : "订阅"
                      }}
                    </a-button>
                  </p>
                </template>
              </a-card-meta>
            </a-card>
          </a-col>
        </a-row>
        <a-row style="padding:20px;">
          <a-col :span="24">
            <a-pagination
              v-model="current"
              :total="total"
              :defaultPageSize="defaultPageSize"
              :showTotal="showTotal"
              @change="pagerChange"
            />
          </a-col>
        </a-row>
      </div>
      <a-form layout="horizontal" style="padding: 10px 0px 0; height: auto">
        <a-tabs default-active-key="0">
          <a-tab-pane key="0" tab="模板列表" />
        </a-tabs>
      </a-form>
      <a-form-item
        label
        style="margin-bottom: 10px; display:none"
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
      >
        <a-form layout="inline">
          <a-form-item label="类型">
            <a-radio-group
              default-value="TypeID"
              v-model="TypeID"
              @change="QueryData"
              button-style="solid"
            >
              <a-radio-button
                :value="item.ID"
                v-for="item in TemplateTypeList"
                :key="item.ID"
              >{{ item.Name }}</a-radio-button>
            </a-radio-group>
          </a-form-item>
        </a-form>
      </a-form-item>
      <a-form-item
        label
        style="margin-bottom: 10px"
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
      >
        <a-form layout="inline">
          <a-form-item label="分类">
            <a-radio-group
              default-value="ClassID"
              v-model="ClassID"
              @change="QueryData"
              button-style="solid"
            >
              <a-radio-button
                :value="item.ID"
                v-for="item in TemplateClassList"
                :key="item.ID"
              >{{ item.Name }}</a-radio-button>
            </a-radio-group>
          </a-form-item>
        </a-form>
      </a-form-item>
      <!-- <a-row :gutter="[16, 16]">
                <a-col :span="4" v-for="info in StoretableData" :key="info.MaterialID">
                    <a-card   @click="ShowDetail(info)" >
                        <img class="thumb-img" slot="cover" :src="StoreGetsrc(info)" />
                        <div class="sc-txt">{{info.Name}}</div>
                    </a-card>
                </a-col>
      </a-row>-->
      <div class="muban">
        <!--<div class="muban-item" @click="ShowDetail(info)" v-for="info in StoretableData" :key="info.MaterialID">
                    <img class="thumb-img" slot="cover" :src="StoreGetsrc(info)" />
        <div class="sc-txt">{{info.Name}}</div>-->

        <div
          class="muban-item"
          @click="NewShowDetail(info)"
          v-for="info in StoretableData"
          :key="info.MaterialID"
        >
          <img class="thumb-img" slot="cover" :src="StoreGetsrc(info)" />
          <div class="sc-txt">{{info.Name}}</div>

          <!--<a-col :span="7">
                        <a-button type="" size="small" :key="info.ID"
                                  @click="NewShowDetail(info)"> <a-icon type="edit"></a-icon>编辑</a-button>
          </a-col>-->
        </div>
      </div>

      <a-row style="margin: 10px 0;padding:20px 0;">
        <a-col :span="24">
          <a-pagination
            v-model="Storecurrent"
            :total="Storetotal"
            :defaultPageSize="StoredefaultPageSize"
            :showTotal="StoreshowTotal"
            @change="StorepagerChange"
          />
        </a-col>
      </a-row>
    </a-layout-content>
    <div v-if="DetailVisible">
      <Detail
        :closeDetail="closeDetail"
        :MaterialType="MaterialType"
        :ID="ID"
        :PushID="PushID"
        :IDType="1"
        :disabled="false"
      ></Detail>
    </div>
  </div>
</template>
<script>
import http from "@/Plugin/Http.js";
import local from "@/Plugin//local.js";
import Detail from "@/Views/MaterialModule/M2Material/Detail";
export default {
  name: "PackageList",
  data() {
    return {
      TemplateClassList: [],
      TemplateTypeList: [],
      tableData: [],
      thispage: 0,
      clear: false,
      StoretableData: [],
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 3 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 }
        }
      },
      Topic: { Name: "全部", ID: 0 },
      Business: { Name: "全行业", ID: 0 },
      current: 1,
      total: 0,
      defaultPageSize: 6,
      showTotal: total => `共 ${total} 条`,
      Storecurrent: 1,
      Storetotal: 0,
      StoredefaultPageSize: 12,
      StoreshowTotal: Storetotal => `共 ${Storetotal} 条`,
      MaterialType: -1,
      PushID: "",
      ID: "0",
      DetailVisible: false,
      TypeID: -1,
      ClassID: -1,
      URLMaterialType: -1
    };
  },
  props: {
    //组件属性
  },
  methods: {
    GetTemplateType: function() {
      var self = this;
      var op = {
        url: "/MarketingModule/Material/GetTemplateType",
        data: {},
        OnSuccess: function(data) {
          self.TemplateTypeList.push({ Name: "全部", ID: -1 });
          data.data.forEach(e => {
            self.TemplateTypeList.push({ Name: e.Name, ID: e.ID });
          });
          self.GetTemplateClass();
        }
      };
      http.Post(op);
    },
    GetTemplateClass: function() {
      var self = this;
      var op = {
        url: "/MarketingModule/Material/GetTemplateClass",
        data: {},
        OnSuccess: function(data) {
          self.TemplateClassList.push({ Name: "全部", ID: -1 });
          data.data.forEach(e => {
            self.TemplateClassList.push({ Name: e.Name, ID: e.ID });
          });
          self.getTableData();
        }
      };
      http.Post(op);
    },
    getTableData: function() {
      var self = this;
      var op = {
        url: "/MarketingModule/Material/GetMaterialPackageList",
        data: {
          Topic: self.Topic.ID,
          pageIndex: self.current,
          PageCount: self.defaultPageSize
        },
        OnSuccess: function(data) {
          self.tableData = data.data.Data;
          self.total = data.data.Total;
          self.defaultPageSize = data.data.RowCount;
          self.current = self.thispage = data.data.PageIndex;
          self.StoregetTableData();
        }
      };
      http.Post(op);
    },
    StoregetTableData: function() {
      var self = this;
      if (self.clear) {
        local.setlocal("ClassID", self.ClassID);
        self.clear=false;
      }

      var op = {
        url: "/MarketingModule/Material/GetMaterialTemplateList",
        data: {
          ClassID: self.ClassID,
          pageIndex: self.Storecurrent,
          MaterialType: self.URLMaterialType,
          PageCount: self.StoredefaultPageSize
        },
        OnSuccess: function(data) {
          self.StoretableData = data.data.Data;
          self.Storetotal = data.data.Total;
          self.StoredefaultPageSize = data.data.RowCount;
          self.Storecurrent = data.data.PageIndex;
        }
      };
      http.Post(op);
    },
    pagerChange: function(item) {
      var self = this;
      self.clear = true;
      self.current = self.thispage = item;
      //local.setlocal("page",item);
      self.getTableData();
    },
    changeTopic: function(e) {
      var self = this;
      self.Topic = e;
      self.current = 1;
      self.getTableData();
    },
    changeBusiness: function(e) {
      var self = this;
      self.Business = e;
      self.current = 1;
      self.getTableData();
    },
    GetPackageRule: function(info) {
      var rt = "";
      if (info.PackageRule == null) {
        rt = "未配置";
      } else {
        switch (info.PackageRule.PublishFrequency) {
          case 0:
            rt = "";
            break;
          case 1:
            rt = "每日AM" + info.PackageRule.PublishTime + "推送";
            break;
          case 2:
            var List = [];
            info.PackageRule.PackageRuleItem.forEach(e => {
              List.push(e.PeriodIndex);
            });
            var PackageRuleItem = List.join("、");
            PackageRuleItem = PackageRuleItem.replace("0", "日")
              .replace("1", "一")
              .replace("2", "二")
              .replace("3", "三")
              .replace("4", "四")
              .replace("5", "五")
              .replace("6", "六");
            rt =
              "每周" +
              PackageRuleItem +
              "AM" +
              info.PackageRule.PublishTime +
              "推送";
            break;
        }
      }
      return rt;
    },
    EditSubscription(e) {
      var self = this;
      self.clear = true;
      var con = e.Subscriptionbool ? "取消订阅" : "订阅";
      this.$confirm({
        title: "提示",
        content: "是否" + con + e.Name,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          var Status = e.Subscriptionbool ? 0 : 1;
          var op = {
            url: "/MarketingModule/Material/EditSubscription",
            data: {
              PackageID: e.ID,
              Status: Status
            },
            OnSuccess: function(data) {
              console.log(data);
              self.getTableData();
              self.$message.success(con + "成功");
            }
          };
          http.Post(op);
        },
        onCancel() {}
      });
    },
    Getsrc: function(info) {
      return info.ImgTopic.replace("upload", "upload2");
    },
    Store_PackageDetail(e) {
      this.$router.push({
        name: "Store_PackageDetail",
        query: { id: e }
      });
    },
    StoreGetsrc: function(info) {
      return info.PreViewImg.replace("upload", "upload2");
    },
    StorepagerChange: function(item) {
      var self = this;
      self.clear = true;
      self.Storecurrent = item;
      local.setlocal("page", item);
      self.StoregetTableData();
    },
    QueryData() {
      var self = this;
      self.Storecurrent = 1;
      self.clear = true;
      self.StoregetTableData();
    },
    ShowDetail(e) {
      var self = this;
      self.clear = true;
      self.MaterialType = e.MaterialType;
      self.PushID = e.ID + "";
      self.DetailVisible = true;
    },
    NewShowDetail: function(e) {
      var self = this;
      self.clear = true;
      self.DetailVisible = true;
      if (e.MaterialType == 5) {
        self.$router.push({
          name: "Store_NewPoster",
          query: {
            MaterialType: e.MaterialType,
            TmplID: e.ID
          }
        });
      }
      if (e.MaterialType == 3) {
        self.GetTemplateMaterialID(e.ID,e);
      }
    },
    //获取店铺模板对应的素材ID
    GetTemplateMaterialID(TmID,e) {
      let self = this;
      let op={
        url:"/MarketingModule/Material/Ac_GetTemplateMaterialID",
        data:{"TempID":TmID},
        OnSuccess:function(data){
          console.log(data.data.Data);
          if(data.data.Result){
            if(data.data.Data!=null){
              self.$router.push({
                name: "Store_MyListShow", query: {
                  mid:data.data.Data,
                  MaterialType: 3,
                  TmplID: TmID,
                }
              });
            }else{
              self.$router.push({
                name: "Store_NewH5",
                query: {
                  MaterialType: e.MaterialType,
                  TmplID: e.ID
                }
              });
            }
          }
        }
      };
      http.Post(op);
    },
    closeDetail(e) {
      console.log(e);
      var self = this;
      self.DetailVisible = false;
    },
    getUrlKey: function(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [""])[1].replace(/\+/g, "%20")
        ) || null
      );
    }
  },
  computed: {
    //计算属性
  },
  watch: {},
  components: {
    Detail
  },
  beforeCreate: function() {
    //生命周期，创建前
  },
  created: function() {
    //生命周期，创建完成
  },
  mounted: function() {
    var self = this;
    //self.clear = true;
    self.URLMaterialType = self.getUrlKey("MaterialType");
    self.URLMaterialType = self.URLMaterialType - 0;

    if (local.getlocal("page") != undefined) {
      self.Storecurrent = self.current = self.thispage = local.getlocal("page");
    }
    if (local.getlocal("ClassID") != undefined) {
      self.ClassID = parseInt(local.getlocal("ClassID"));
    }

    self.GetTemplateClass();
  },  
  beforeDestroy: function() {
    //生命周期，销毁前
    if (!this.clear) {
      local.clearlocal();
    }
  },
  destroyed: function() {
    //生命周期，销毁完成
    //local.clearlocal();
  }
};
</script>
<style scoped>
.muban {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 25px;
  padding: 25px;
  width: 100%;
  margin: 0 auto;
}

.muban-item {
  border: 1px solid #f4f4f4;
  border-radius: 8px;
  overflow: hidden;
  max-height: 505px;
}

.muban-item:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
  transition: all ease 0.3s;
}

.muban-item:hover .sc-txt {
  color: #2281f8;
}

.sc-txt {
  display: block;
  font-size: 16px;
  line-height: 20px;
  color: #3b3b3b;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  padding: 8px 5px;
}

.sc-txt:hover {
  color: #2281f8;
}

::v-deep .ant-card-body {
  padding: 5px;
}

.mintro {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-align: left !important;
  margin: 0;
  font-size: 12px;
  white-space: normal;
}

.mintro2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-align: left !important;
  margin: 0;
  font-size: 12px;
  height: 36px;
  white-space: normal;
}

.thumb-img1 {
  height: 150px;
  object-fit: cover;
  outline: none;
  background: url("../../../../src/assets/nourl.png");
  background-size: cover;
}

.thumb-img {
  cursor: pointer;
  min-height: 385px;
  border-radius: 5px;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 10px 10px 0 0;
  background: #ebebeb url("../../../../src/assets/nourl.png") no-repeat center
    center;
  background-size: contain;
  background-repeat: no-repeat;
}

.thumb-img:hover {
  opacity: 0.85;
}

.option {
  margin: 10px 0;
}
</style>